<template>
  <div class="section">
    <modal-add-category
      v-if="modalCategory"
      :close="openAddCategory"
      :action="addCategory"
    />
    <modal-edit-category
      v-if="modalEditCategory"
      :close="openEditCategory"
      :categoryDetail="this.categoryDetail"
      :action="saveCategory"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title-section">
            Categorías
          </h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            @click="openAddCategory"
            class="button button-givu has-text-centered-mobile"
          >
            Agregar Categoría
          </button>
        </div>
      </div>
    </nav>
    <div class="levelBack">
      <router-link
        :to="{ name: 'blog' }"
        class="link-back"
      >
        <i class="material-icons">keyboard_arrow_left</i>
        <span> Regresar </span>
      </router-link>
    </div>
    <div class="columns is-multiline">
      <categories-list
        :categoryList="categoryList"
        :page="page"
        :pages="pages"
        :loading="loading"
        :paginator="paginator"
        :changeStatus="changeStatus"
        :openEdit="openEditCategory"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Categories',
  components: {
    CategoriesList: () => import('@/components/Blog/CategoriesList'),
    ModalAddCategory: () => import('@/components/Blog/ModalAddCategory'),
    ModalEditCategory: () => import('@/components/Blog/ModalEditCategory')
  },
  data () {
    return {
      categoryList: [],
      page: 1,
      pages: 1,
      loading: false,
      modalCategory: false,
      modalEditCategory: false,
      categoryDetail: null
    }
  },
  methods: {
    ...mapActions(['getCategoriesList', 'addCategories', 'changeStatusBlogCategory', 'updateBlogCategory']),
    paginator (page) {
      this.page = page
      this.getCategoryList(this.page)
    },
    async getCategoryList (page) {
      this.loading = true
      this.getCategoriesList({ page, size: DEFAULT_DISPLAY_SIZE })
        .then(resp => {
          this.categoryList = resp.payload
          this.pages = resp.totalPage
          this.loading = false
        })
    },
    async addCategory (category) {
      const response = await this.addCategories(category)
      if (response) {
        this.getCategoryList(this.page)
        this.openAddCategory()
      }
    },
    openAddCategory () {
      this.modalCategory = !this.modalCategory
    },
    changeStatus (categoryId) {
      this.changeStatusBlogCategory(categoryId).then(() => {
        this.getCategoryList(this.page)
      })
    },
    openEditCategory (categoryElement) {
      this.modalEditCategory = !this.modalEditCategory
      this.categoryDetail = categoryElement || null
    },
    saveCategory (data) {
      this.updateBlogCategory(data).then(() => {
        this.openEditCategory()
        this.getCategoryList(this.page)
      })
    }
  },
  beforeMount () {
    this.loading = true
    this.getCategoriesList({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.categoryList = resp.payload
        this.pages = resp.totalPage
        this.loading = false
      })
  }
}
</script>

<style scoped>
  .section {
    padding: 0 20px 0 20px;
  }
  .levelBack {
    padding-bottom: 10px ;
  }
  .link-back {
    display: flex;
    align-items: center;
  }
</style>
